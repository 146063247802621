<template>

  <v-container class="py-0 px-0">
    <div class="position-relative"
      :style="`background: url(${require('@/assets/img/lottery-area.jpg')}) top center fixed; background-size: cover;`">
      <v-row no-gutters class="mx-4 text-center">
        <!--<v-col cols="12" class="align-center justify-center text-center">
          <v-container class="text-center justify-self-auto">
            <div style="display: inline-flex; align-items: center; justify-items: center;" class="text-center">
              <v-img src="@/assets/img/daily_cash.svg" style="display: inline-block; max-width:40%"></v-img>
              <h3 class="text-h3 text-typo font-weight-bold mx-0" style="display: inline-block">
                近期開獎
              </h3>
          </div>
          </v-container>
        </v-col>-->
        <v-col cols="12" align="center" justify="center" class="align-center justify-center text-center">
          <v-container class="text-center">
            <div style="display: flex; align-items: center; justify-items: center; align-items: center; justify-content: center;" class="text-center">
            <v-img src="@/assets/img/daily_cash.svg" style="display: flex; max-width:40%"></v-img>
            <h3 class="text-h3 text-typo font-weight-bold mx-0" style="display: inline-flex">
              近期開獎
            </h3>
          </div>
          </v-container>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col v-for="(item, i) in list" :key="i" sm="4" cols="12">
              <v-card class="card-shadow border-radius-xl pt-1">
                <v-row class="px-4">
                  <v-col sm="4">
                    <h5 class="
                        text-h5
                        mb-1
                        font-weight-bold
                      " style="color:#1A237E">
                      第 {{ item.draw_term }} 期
                    </h5>
                    <h6 class="
                        text-h6
                        mb-1
                        font-weight-bold
                      " style="color:#1A237E">
                      開獎日期：{{ item.date }}
                    </h6>
                    <v-card>
                      <m539-balls :balls="item.balls"></m539-balls>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Cookie from "js-cookie";
import M539Balls from "./Widgets/M539Balls.vue";
import { m539Data } from "@/apis/lotteryHistoryService";

export default {
  name: "LotteryArea",
  components: {
    M539Balls,
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    const token = Cookie.get('auth')
    const tokenParts = token.split('.')
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    const data = {
      'offset': 0,
      'size': 20,
    };

    m539Data(data)
      .then(response => {
        this.repsData = response.data;
        console.log(this.repsData)
        this.list = this.repsData.list

      })
      .catch(error => { // 请求失败处理
        console.log(error);
      });

  },
};
</script>
