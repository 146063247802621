<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col v-for="(v, index) in sortBalls" :key="index" :value="v"
          class="d-flex align-center justify-center mx-0">
          <v-img contain :max-height="imgHeight" :max-width="imgWidth"
            :src="require('@/assets/img/balls/539/' + v + '.png')">
          </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'M539Balls',
  props: ["balls"],
  data() {
    return {
      sortBalls: [],
      imgHeight: 50,
      imgWidth: 50,
    }
  },
  mounted() {
    console.log(this.balls)
    this.sortBalls = this.balls.sort(function (a, b) { return a - b; });
    console.log(this.sortBalls);
  }

}
</script>